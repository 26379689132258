/** @jsx jsx **/
import { Themed } from '@theme-ui/mdx'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Text, jsx } from 'theme-ui'
import { hubspotFormIds } from '~/common/hubspot'
import MarkdownIt from '~/components/MarkdownIt'
import Layout from '~/components/layout'
import { HubspotForm } from '~/utils/hubspotForm'

type QueryResult = {
  pageData: {
    frontmatter: {
      title: string
      note: string
    }
    html: string
  }
}

const FirstOrderSaleTemplate = () => {
  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "firstOrderSale" } }) {
        frontmatter {
          title
          note
        }
        html
      }
    }
  `)
  const {
    frontmatter: { title, note },
    html: content,
  } = query.pageData
  return (
    <Layout headerVariant="default" title={title}>
      <Container
        variant="containerPrimary"
        sx={{
          my: [50, null, 100],
        }}
      >
        <Container
          sx={{
            maxWidth: 460,
            p: 0,
            textAlign: 'center',
          }}
        >
          <Themed.h4
            as="h1"
            sx={{
              mb: [37, null, 82],
            }}
          >
            {title}
          </Themed.h4>
        </Container>
        <Container
          sx={{
            maxWidth: 576,
            p: 0,
            mb: [70, null, 120],
          }}
        >
          <HubspotForm formId={hubspotFormIds.newsletterCatalog} />
        </Container>
        <MarkdownIt content={content} />
        <Container
          sx={{
            maxWidth: 742,
            p: 0,
            mt: 32,
            textAlign: ['left', null, 'center'],
          }}
        >
          <Text variant="micro">{note}</Text>
        </Container>
      </Container>
    </Layout>
  ) as JSX.Element
}

export default FirstOrderSaleTemplate
